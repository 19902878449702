import { configureStore } from '@reduxjs/toolkit';
import paginationSlice from './slices/paginationSlice';
import searchSlice from './slices/searchSlice';
import tableSlice from './slices/tableSlice';

export const store = configureStore({
  reducer: {
    search: searchSlice,
    table: tableSlice,
    pagination: paginationSlice,
  },
});
