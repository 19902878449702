export const trendCalculator = (month, threeMonth) => {
  if (!threeMonth || !month) return 0;
  let currentValue = (threeMonth / 3 / month) * 100 - 100;
  if (currentValue < 0) {
    currentValue = Math.abs(currentValue);
  } else {
    currentValue = Number('-' + currentValue);
  }

  if (!currentValue) return 0;
  return currentValue.toFixed(1);
};
