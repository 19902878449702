import React from 'react';
import { useState } from 'react';
import styles from './Pagination.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

const Pagination = ({ total, current, paginate }) => {
  const [active, setActive] = useState(1);
  const inputValue = useSelector((state) => state.search.value);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(total / current); i++) {
    pageNumbers.push(i);
  }

  const onClick = (number) => {
    paginate(number);
    setActive(number);
  };

  useEffect(() => {
    setActive(1);
  }, [inputValue]);

  return (
    <div>
      <ul className={styles.pagination}>
        {pageNumbers.map((number) => (
          <li
            onClick={() => onClick(number)}
            key={number}
            className={
              active === number
                ? [styles.pagination__item, styles.active].join(' ')
                : styles.pagination__item
            }>
            {number}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Pagination;
