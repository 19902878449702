import React from 'react';
import styles from './Akin.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { onChangeValue } from '../../redux/slices/searchSlice';

const Akin = ({ akin }) => {
  const dispatch = useDispatch();

  const onAddValue = (e) => {
    dispatch(onChangeValue(e));
    console.log(e);
  };

  return (
    <div className={styles.akin}>
      {akin &&
        akin.map((e) => (
          <div key={e} onClick={() => onAddValue(e)} className={styles.akin__item}>
            {e}
          </div>
        ))}
    </div>
  );
};

export default Akin;
