export const sortItems = (items, type) => {
  if (type === 'name-') {
    return items.sort((a, b) => {
      if (a.name > b.name) {
        return -1;
      }
      if (a.name < b.name) {
        return 1;
      }
      return 0;
    });
  }
  if (type === 'name+') {
    return items.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }
  if (type === 'month-') {
    return items.sort((a, b) => {
      if (a.month < b.month) {
        return -1;
      }
      if (a.month > b.month) {
        return 1;
      }
      return 0;
    });
  }
  if (type === 'month+') {
    return items.sort((a, b) => {
      if (a.month > b.month) {
        return -1;
      }
      if (a.month < b.month) {
        return 1;
      }
      return 0;
    });
  }
  if (type === 'procent-') {
    return items.sort((a, b) => {
      if (Number(a.procent) < Number(b.procent)) {
        return -1;
      }
      if (Number(a.procent) > Number(b.procent)) {
        return 1;
      }
      return 0;
    });
  }
  if (type === 'procent+') {
    return items.sort((a, b) => {
      if (Number(a.procent) > Number(b.procent)) {
        return -1;
      }
      if (Number(a.procent) < Number(b.procent)) {
        return 1;
      }
      return 0;
    });
  }
  if (type === 'countCard-') {
    return items.sort((a, b) => {
      if (Number(a.countCard) < Number(b.countCard)) {
        return -1;
      }
      if (Number(a.countCard) > Number(b.countCard)) {
        return 1;
      }
      return 0;
    });
  }
  if (type === 'countCard+') {
    return items.sort((a, b) => {
      if (Number(a.countCard) > Number(b.countCard)) {
        return -1;
      }
      if (Number(a.countCard) < Number(b.countCard)) {
        return 1;
      }
      return 0;
    });
  }
};
