import React from 'react';
import styles from './Filter.module.scss';

const Filter = ({ currentType, type }) => {
  return (
    <div className={styles.filter}>
      <div
        className={
          currentType === `${type}+`
            ? [styles.filter__top, styles.filter__top_active].join(' ')
            : styles.filter__top
        }></div>
      <div
        className={
          currentType === `${type}-`
            ? [styles.filter__bottom, styles.filter__bottom_active].join(' ')
            : styles.filter__bottom
        }></div>
    </div>
  );
};

export default Filter;
