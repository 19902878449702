export const fetchCard = (items) => {
  const current = [];
  for (let i = 0; i < items.length; i++) {
    fetch(
      `https://search.wb.ru/exactmatch/ru/common/v4/search?appType=1&couponsGeo=12,3,18,15,21&curr=rub&dest=-1257786&emp=0&filters=xsubject&lang=ru&locale=ru&pricemarginCoeff=1.0&query=${items[i].name}&reg=1&regions=80,64,38,4,115,83,33,68,70,69,30,86,75,40,1,66,48,110,22,31,71,114,111&resultset=filters&spp=22&sppFixGeo=4`,
    )
      .then((res) => res.json())
      .then((res) => current.push([res.data.total, items[i].name]));
  }
  return current;
};
