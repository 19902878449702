import Header from './components/Header/Header';
import SearchQuery from './pages/SearchQuery/SearchQuery';

function App() {
  return (
    <div className="wrapper">
      <Header />
      <SearchQuery />
    </div>
  );
}

export default App;
