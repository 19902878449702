import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { cardSearch } from '../../toolkit/cardSearch';
import { sortItems } from '../../toolkit/sortItems';
import { trendCalculator } from '../../toolkit/trendCalculator';

export const weekFetch = createAsyncThunk('table/weekFetch', async (value) => {
  let result = [];
  const one = await axios.get(
    `https://trending-searches.wb.ru/api?itemsPerPage=900&offset=0&period=month&query=${value}&sort=desc`,
  );
  const three = await axios.get(
    `https://trending-searches.wb.ru/api?itemsPerPage=950&offset=0&period=3month&query=${value}&sort=desc`,
  );
  if (three && one) {
    result = one.data.data.list.map((e, i) => {
      let procent = 0;
      let countCard = 0;
      if (three.data.data.count > i) {
        procent = trendCalculator(e.requestCount, three.data.data.list[i].requestCount);
      }

      // fetch(
      //   `https://search.wb.ru/exactmatch/ru/common/v4/search?appType=1&couponsGeo=12,3,18,15,21&curr=rub&dest=-1257786&emp=0&filters=xsubject&lang=ru&locale=ru&pricemarginCoeff=1.0&query=${e.text}&reg=1&regions=80,64,38,4,115,83,33,68,70,69,30,86,75,40,1,66,48,110,22,31,71,114,111&resultset=filters&spp=22&sppFixGeo=4`,
      // )
      //   .then((res) => res.json())
      //   .then((res) => (countCard = res.data.total));

      return {
        name: e.text,
        month: e.requestCount,
        procent: procent,
        countCard: countCard,
      };
    });
  }

  // let current = [];
  // for (let i = 0; i < result.length; i++) {
  //   fetch(
  //     `https://search.wb.ru/exactmatch/ru/common/v4/search?appType=1&couponsGeo=12,3,18,15,21&curr=rub&dest=-1257786&emp=0&filters=xsubject&lang=ru&locale=ru&pricemarginCoeff=1.0&query=${result[i]}&reg=1&regions=80,64,38,4,115,83,33,68,70,69,30,86,75,40,1,66,48,110,22,31,71,114,111&resultset=filters&spp=22&sppFixGeo=4`,
  //   )
  //     .then((res) => res.json())
  //     .then((res) => result.push([res.data.total]));
  // }

  // let count = [];

  // for (let i = 0; i < result.length; i++) {
  //   console.log(result[i].name);
  //   fetch(
  //     `https://search.wb.ru/exactmatch/ru/common/v4/search?appType=1&couponsGeo=12,3,18,15,21&curr=rub&dest=-1257786&emp=0&filters=xsubject&lang=ru&locale=ru&pricemarginCoeff=1.0&query=${result[i].name}&reg=1&regions=80,64,38,4,115,83,33,68,70,69,30,86,75,40,1,66,48,110,22,31,71,114,111&resultset=filters&spp=22&sppFixGeo=4`,
  //   )
  //     .then((res) => res.json())
  //     .then((res) => count.push(res.data.total));
  // }

  // console.log(count, 'count');

  return result;
});

const initialState = {
  items: [],
  type: '',
  currentCard: [],
};

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    sortItemsTable: (state, action) => {
      state.items = sortItems(state.items, action.payload);
      state.type = action.payload;
    },
    addCard: (state, action) => {
      state.currentCard = [...state.currentCard, ...action.payload];
      state.items = state.items.map((e, i) => {
        let current = 0;
        let text = e.name;
        state.currentCard.forEach((element) => {
          if (element[1] === text) {
            current = element[0];
          }
        });
        e.countCard = current;
        return e;
      });
      state.currentCard = state.items[0].name;
    },
  },
  extraReducers: {
    [weekFetch.pending]: (state, action) => {
      state.items = [];
    },
    [weekFetch.fulfilled]: (state, action) => {
      state.items = action.payload;
      state.type = '';
    },
    [weekFetch.rejected]: (state) => {
      state.items = [];
    },
  },
});

export const { sortItemsTable, addCard } = tableSlice.actions;

export default tableSlice.reducer;
