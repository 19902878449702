import React, { useState, useRef, useEffect } from 'react';
import Akin from '../../components/Akin/Akin';
import Table from '../../components/Table/Table';
import { useSelector, useDispatch } from 'react-redux';

import styles from './SearchQuery.module.scss';
import { onChangeValue } from '../../redux/slices/searchSlice';
import { addCard, weekFetch } from '../../redux/slices/tableSlice';
import { fetchCard } from '../../toolkit/fetchCard';

const SearchQuery = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [currentItems, setCurrentItems] = useState([]);
  const [two, setTwo] = useState([]);
  const [twoStep, setTwoStep] = useState(false);
  const [count, setCount] = useState(0);
  const items = useSelector((state) => state.table.items);
  const type = useSelector((state) => state.table.type);
  const currentCard = useSelector((state) => state.table.currentCard);
  const [firstMonth, setFirstMonth] = useState([{ text: 'ok' }]);
  const [firstThreeMonth, setFirstThreeMonth] = useState([{ text: 'ok' }]);
  const [akin, setAkin] = useState('');

  const inputValue = useSelector((state) => state.search.value);

  useEffect(() => {
    if (inputValue.length > 1) {
      fetch(
        `https://similar-queries.wildberries.ru/api/v2/search/query?query=${inputValue}&spp=28&regions=80,64,38,4,83,33,68,70,69,30,86,75,40,1,22,66,31,48,110,71&pricemarginCoeff=1.0&reg=1&appType=1&emp=0&locale=ru&lang=ru&curr=rub&couponsGeo=12,3,18,15,21&sppFixGeo=4&dest=-1257786`,
      )
        .then((res) => res.json())
        .then((data) => setAkin(data.query));
    }
    setTwoStep(false);
  }, [inputValue]);

  useEffect(() => {
    if (type === '') {
      let current = [];
      let two = [];
      if (currentCard !== items[0]?.name) {
        // for (let i = 0; i < items.length; i++) {
        //   fetch(
        //     `https://search.wb.ru/exactmatch/ru/common/v4/search?appType=1&couponsGeo=12,3,18,15,21&curr=rub&dest=-1257786&emp=0&filters=xsubject&lang=ru&locale=ru&pricemarginCoeff=1.0&query=${items[i].name}&reg=1&regions=80,64,38,4,115,83,33,68,70,69,30,86,75,40,1,66,48,110,22,31,71,114,111&resultset=filters&spp=22&sppFixGeo=4`,
        //   )
        //     .then((res) => res.json())
        //     .then((res) => current.push([res.data.total, items[i].name]));
        // }
        current = fetchCard(items.slice(0, 449));
        setTimeout(() => {
          setCurrentItems(current);
          setTwoStep(true);
        }, 5000);
      }
    }
  }, [items]);

  useEffect(() => {
    let current = [];
    current = fetchCard(items.slice(450));
    setTimeout(() => {
      setTwo(current);
    }, 5000);
  }, [twoStep]);

  useEffect(() => {
    if (two.length > 0) {
      dispatch(addCard([...currentItems, ...two]));
    }
  }, [two]);

  useEffect(() => {
    if (currentItems.length > 0) {
      dispatch(addCard(currentItems));
    }
  }, [currentItems]);

  // useEffect(() => {
  //   if (inputValue.length < 1) {
  //     fetch(
  //       `https://trending-searches.wb.ru/api?itemsPerPage=20&offset=0&period=month&query=&sort=desc`,
  //     )
  //       .then((res) => res.json())
  //       .then(
  //         (data) => setFirstMonth(data.data.list),
  //         setCount((count) => count + 1),
  //       );
  //   }
  // }, []);

  // useEffect(() => {
  //   if (inputValue.length < 1) {
  //     fetch(
  //       `https://trending-searches.wb.ru/api?itemsPerPage=22&offset=0&period=3month&query=&sort=desc`,
  //     )
  //       .then((res) => res.json())
  //       .then(
  //         (data) => setFirstThreeMonth(data.data.list),
  //         setCount((count) => count + 1),
  //       );
  //   }
  // }, []);

  useEffect(() => {
    if (inputValue !== ref.current.value) {
      ref.current.value = inputValue;
    }
    dispatch(weekFetch(inputValue));
  }, [inputValue]);

  const onSearch = () => {
    dispatch(onChangeValue(ref.current.value));
    dispatch(weekFetch(ref.current.value));
  };

  console.log(currentItems);

  return (
    <div className={styles.search}>
      <div className={styles.search__form}>
        <div onKeyDown={(e) => e.code === 'Enter' && onSearch()} className={styles.search__input}>
          <input ref={ref} type="text" placeholder="поиск" />
        </div>
        <button onClick={onSearch} className={styles.search__button}>
          Найти
        </button>
      </div>
      <div className={styles.search__similar}>
        <Akin akin={akin} />
      </div>
      {items.length < 1 ? (
        <div className={styles.search__table}>
          <Table count={count} itemsMonth={firstMonth} itemsThreeMonth={firstThreeMonth} />
        </div>
      ) : (
        <div className={styles.search__table}>
          <Table items={items} />
        </div>
      )}
    </div>
  );
};

export default SearchQuery;
