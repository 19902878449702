import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortItemsTable } from '../../redux/slices/tableSlice';
import { trendCalculator } from '../../toolkit/trendCalculator';
import Filter from '../Filter/Filter';
import Pagination from '../Pagination/Pagination';

import styles from './Table.module.scss';

const Table = ({ items }) => {
  const [name, setName] = useState(false);
  const [month, setMonth] = useState(false);
  const [procent, setProcent] = useState(false);
  const [card, setCard] = useState(false);
  const dispatch = useDispatch();
  const currentType = useSelector((state) => state.table.type);
  const inputValue = useSelector((state) => state.search.value);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [quantity] = useState(50);
  const lastIndex = currentPage * quantity;
  const firstIndex = lastIndex - quantity;
  const currentItems = items?.slice(firstIndex, lastIndex);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [inputValue]);

  const Onsort = (value) => {
    if (value === 'name') {
      dispatch(sortItemsTable(`name${name ? '+' : '-'}`));
      setName(!name);
    }
    if (value === 'month') {
      dispatch(sortItemsTable(`month${month ? '+' : '-'}`));
      setMonth(!month);
    }
    if (value === 'procent') {
      dispatch(sortItemsTable(`procent${procent ? '+' : '-'}`));
      setProcent(!procent);
    }
    if (value === 'countCard') {
      dispatch(sortItemsTable(`countCard${card ? '+' : '-'}`));
      setCard(!card);
    }
  };

  return (
    <>
      {' '}
      <div className={styles.table}>
        <div className={styles.table__top}>
          <div onClick={() => Onsort('name')} className={styles.table__title}>
            Поисковый запрос <Filter type="name" currentType={currentType} />
          </div>
          <div onClick={() => Onsort('month')} className={styles.table__title}>
            К-во запросов за 1 месяц <Filter type="month" currentType={currentType} />
          </div>
          <div onClick={() => Onsort('procent')} className={styles.table__title}>
            тренд <Filter type="procent" currentType={currentType} />
          </div>
          <div onClick={() => Onsort('countCard')} className={styles.table__title}>
            К-во карточек <Filter type="countCard" currentType={currentType} />
          </div>
        </div>
        <div className={styles.table__body}>
          {currentItems?.length > 0 ? (
            currentItems.map((e, i) => {
              return (
                <div key={i} className={styles.table__items}>
                  <div className={styles.table__item}>{e.name}</div>
                  <div className={styles.table__item}>{e.month}</div>
                  <div
                    style={{ color: e.procent > 0 ? 'green' : 'red' }}
                    className={styles.table__item}>
                    {e.procent} %
                  </div>
                  <div className={styles.table__item}>{e?.countCard}</div>
                </div>
              );
            })
          ) : (
            <p></p>
          )}
        </div>
      </div>
      <Pagination paginate={paginate} total={items?.length} current={quantity} />
    </>
  );
};

export default Table;
