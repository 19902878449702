import React from 'react';
import styles from './Header.module.scss';
import { onChangeValue } from '../../redux/slices/searchSlice';
import { addCard, weekFetch } from '../../redux/slices/tableSlice';
import { useSelector, useDispatch } from 'react-redux';

const Header = () => {
  const dispatch = useDispatch();
  const inputValue = useSelector((state) => state.search.value);
  const clear = () => {
    if (inputValue !== '') {
      dispatch(onChangeValue(''));
      dispatch(weekFetch(''));
    }
  };
  return (
    <header className={styles.header}>
      <button onClick={clear} className={styles.header__button}>
        Топ запросов
      </button>
    </header>
  );
};

export default Header;
